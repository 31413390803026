import React from "react";
import uploadimg from "../pics/uploadimg.png";
import logolight from "../pics/logolight.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";
export default function Upload() {
   const [formData, setFormData] = useState({
      title: "",
      link: "",
      gatag: "",
      github: "",
      image: null,
      isProject: false,
   });

   const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({
         ...formData,
         [name]: value,
      });
   };

   const handleImageChange = (event) => {
      setFormData({
         ...formData,
         image: event.target.files[0],
      });
   };

   const handleRadioChange = (event) => {
      setFormData({
         ...formData,
         isProject: event.target.value === "project",
      });
   };

   const handleSubmit = (event) => {
      const data = new FormData();
      data.append("image", formData.image);
      data.append("title", formData.title);
      data.append("link", formData.link);
      data.append("gatag", formData.gatag);
      event.preventDefault();
      const url = formData.isProject
         ? "https://portfolio-api-seven.vercel.app/upload/project"
         : "https://portfolio-api-seven.vercel.app/upload/certificate";
      if (formData.isProject) {
         data.append("github", formData.github);
      }
      fetch(url, {
         method: "POST",
         headers: {},
         body: data,
      })
         .then((res) => res.json())
         .then((response) => {
            if (response.message === "Project created successfully") {
               Swal.fire({
                  icon: "success",
                  title: "Success",
                  text: "Project created successfully!",
               });
            } else {
               Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
               });
            }
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const [authData, setAuthData] = useState({
      email: "",
      password: "",
   });

   const [isLoggedIn, setIsLoggedIn] = useState(false);

   const handleAuthInputChange = (event) => {
      const { name, value } = event.target;
      setAuthData({
         ...authData,
         [name]: value,
      });
   };

   const handleLogin = (event) => {
      event.preventDefault();
      const url = "https://portfolio-api-seven.vercel.app/Authentication";
      const options = {
         method: "POST",
         body: JSON.stringify(authData),
         headers: {
            "Content-Type": "application/json",
         },
      };
      fetch(url, options)
         .then((response) => response.json())
         .then((data) => {
            if (data.success) {
               setIsLoggedIn(true);
            } else {
               console.log(authData);
               toast.error("Invalid email or password");
            }
         })
         .catch((error) => console.error(error));
   };

   return (
      <div className=" row row-cols-md-2 login signop row-cols-1 m-0 ">
         <div className=" col secondpart mt-4 ">
            <div className="container text-center">
               <Link to="/">
                  <img
                     src={logolight}
                     alt="logo"
                     className="mb-4 text-center mt-4 img-fluid "
                  />
               </Link>
               {!isLoggedIn ? (
                  <div className="container">
                     <form onSubmit={handleLogin}>
                        <div className="row">
                           <div className="col-md-12 mb-1">
                              <div className="input-container">
                                 {" "}
                                 <label htmlFor="email" className="label">
                                    Email:
                                 </label>
                                 <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={authData.email}
                                    onChange={handleAuthInputChange}
                                    required
                                    className="form-control"
                                 />
                                 <div className="underline"></div>
                              </div>
                           </div>
                           <div className="col-md-12 mb-1">
                              <div className="input-container">
                                 {" "}
                                 <label htmlFor="password" className="label">
                                    Password:
                                 </label>
                                 <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={authData.password}
                                    onChange={handleAuthInputChange}
                                    required
                                    className="form-control"
                                 />
                                 <div className="underline"></div>
                              </div>
                           </div>
                           <div className="d-flex justify-content-center mt-1 mb-3">
                              <button type="submit" className="btn btn-primary">
                                 Login
                              </button>
                           </div>
                        </div>
                     </form>
                  </div>
               ) : (
                  <>
                     <h2>Upload</h2>
                     <h6 className="mb-5">Hey there, welcome back</h6>
                     <div className="container">
                        <form onSubmit={handleSubmit}>
                           <div className="form-group">
                              <label htmlFor="title">Title:</label>
                              <input
                                 type="text"
                                 className="form-control"
                                 id="title"
                                 name="title"
                                 value={formData.title}
                                 onChange={handleInputChange}
                                 required
                                 placeholder="Enter title"
                              />
                           </div>

                           <div className="form-group">
                              <label htmlFor="gatag">Gatag:</label>
                              <input
                                 type="text"
                                 className="form-control"
                                 id="gatag"
                                 name="gatag"
                                 value={formData.gatag}
                                 onChange={handleInputChange}
                                 required
                                 placeholder="Enter gatag"
                              />
                           </div>

                           <div className="form-group">
                              <label htmlFor="link">Link:</label>
                              <input
                                 type="text"
                                 className="form-control"
                                 id="link"
                                 name="link"
                                 value={formData.link}
                                 onChange={handleInputChange}
                                 required
                                 placeholder="Enter link"
                              />
                           </div>

                           <div className="form-group">
                              <label htmlFor="image">Image:</label>
                              <input
                                 type="file"
                                 className="form-control-file"
                                 id="image"
                                 name="image"
                                 onChange={handleImageChange}
                                 required
                              />
                           </div>

                           <div className="form-group">
                              <div className="form-check form-check-inline">
                                 <input
                                    className="form-check-input"
                                    type="radio"
                                    name="product-type"
                                    id="project-radio"
                                    value="project"
                                    checked={formData.isProject}
                                    onChange={handleRadioChange}
                                 />
                                 <label
                                    className="form-check-label"
                                    htmlFor="project-radio"
                                 >
                                    Project
                                 </label>
                              </div>
                              <div className="form-check form-check-inline">
                                 <input
                                    className="form-check-input"
                                    type="radio"
                                    name="product-type"
                                    id="certificate-radio"
                                    value="certificate"
                                    checked={!formData.isProject}
                                    onChange={handleRadioChange}
                                 />
                                 <label
                                    className="form-check-label"
                                    htmlFor="certificate-radio"
                                 >
                                    Certificate
                                 </label>
                              </div>
                           </div>

                           {formData.isProject && (
                              <div className="form-group">
                                 <label htmlFor="github">github:</label>
                                 <input
                                    type="text"
                                    className="form-control"
                                    id="github"
                                    name="github"
                                    value={formData.github}
                                    onChange={handleInputChange}
                                    placeholder="Enter github"
                                 />
                              </div>
                           )}

                           <button type="submit" className="btn btn-primary">
                              Submit
                           </button>
                        </form>
                        <Toaster />
                     </div>{" "}
                  </>
               )}
            </div>
         </div>
         <div className="col firstpart">
            <div className="imgbox">
               <img src={uploadimg} alt="upload" className="img-fluid" />
            </div>
         </div>
         <Toaster />
      </div>
   );
}
