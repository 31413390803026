import React from "react";
import Card from "../components/card";
import LazyLoad from "react-lazyload";

export default function Projects() {
   const [projects, setProjects] = React.useState([]);
   const [visibleProjects, setVisibleProjects] = React.useState([]);
   const [showMore, setShowMore] = React.useState(
      window.innerWidth >= 767 ? 3 : 2
   );

   React.useEffect(() => {
      fetch("https://portfolio-api-seven.vercel.app/projects")
         .then((res) => res.json())
         .then((data) => {
            setProjects(data.projects);
            setVisibleProjects(data.projects.slice(0, showMore));
         });
   }, [showMore]);

   const showMoreProjects = () => {
      setShowMore((prev) => prev + (window.innerWidth >= 767 ? 6 : 4));
   };
   const handleShowLess = () => {
      setShowMore(window.innerWidth >= 767 ? 3 : 2);
   };

   return (
      <>
         <div className="row row-cols-md-3 m-0 row-cols-2 splide__slide">
            {visibleProjects.map((item) => (
               <div key={item._id}>
                  <div className="justify-content-center">
                     <LazyLoad height={200}>
                        <Card
                           id={item._id}
                           title={item.title}
                           gatag={item.gatag}
                           link={item.link}
                           github={item.github}
                           image={item.image}
                        />
                     </LazyLoad>
                  </div>
               </div>
            ))}
         </div>
         {projects.length > showMore ? (
            <div className="col-12 mt-4 justify-content-center d-flex">
               <button className="seemore mb-3" onClick={showMoreProjects}>
                  SEE MORE ({projects.length - showMore} more)
               </button>
            </div>
         ) : projects.length > (window.innerWidth >= 767 ? 3 : 2) ? (
            <div className="col-12 mt-4 justify-content-center d-flex">
               <button className="seemore mb-3" onClick={handleShowLess}>
                  SHOW LESS
               </button>
            </div>
         ) : null}
      </>
   );
}
