import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logonav from "../pics/logo.png";
function Navbar() {
   return (
      <div className="navi d-flex justify-content-between sticky-top" id="nav-me">
         <div className="navi-logo align-items-center d-flex ">
            <Link className="navbar-brand align-items-center " to="/">
               <img className=" img-fluid " src={logonav} alt="Logo "></img>
            </Link>
         </div>
         <div className="navi-links align-items-center">
            <ul className="nav-btns">
               <li>
                  <Link to="/">Home</Link>
               </li>
               <li>
                  <Link to="about">About</Link>
               </li>
               <li>
                  <Link to="works">Works</Link>
               </li>
               <li>
                  <Link to="contact">Contact</Link>
               </li>
            </ul>
         </div>
         <div className="navi-btn align-items-center d-flex">
            <button className=" mybtn-one ">
               <Link to="contact">Let's Talk</Link>
            </button>
         </div>
      </div>
   );
}

export default Navbar;
