import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import PlaceholderImage from "../pics/moud.jpg";
export default function Card(props) {
   const [rotateX, setRotateX] = useState(0);
   const [rotateY, setRotateY] = useState(0);
   const [transition, setTransition] = useState("");

   const handleMouseMove = (event) => {
      const card = event.currentTarget;
      const centerX = card.offsetLeft + card.clientWidth / 2;
      const centerY = card.offsetTop + card.clientHeight / 2;
      const moveX = (event.clientX - centerX) / 20;
      const moveY = (event.clientY - centerY) / -20;
      setRotateX(moveY);
      setRotateY(moveX);
      setTransition("transform 0.5s ease-out");
   };

   const handleMouseLeave = () => {
      setRotateX(0);
      setRotateY(0);
      setTransition("transform 0.5s ease-out");
   };

   const image = `..${props.image}`;

   return (
      <div
         className={`container-card me-auto ms-auto`}
         onMouseMove={handleMouseMove}
         onMouseLeave={handleMouseLeave}
      >
         <div
            className={`card-card mb-4`}
            style={{
               transform: `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
               transition: transition,
            }}
         >
            <div
               className="bike"
               style={{
                  transform: `rotateX(-${rotateX}deg) rotateY(-${rotateY}deg)`,
                  transition: transition,
               }}
            >
               <div className="circle"></div>
               <a
                  href={props.link}
                  className=" text-decoration-none"
                  style={{ zIndex: 2 }}
               >
                  <LazyLoadImage
                     src={image}
                     alt={props.title}
                     className="img-fluid animate__animated animate__fadeInDown "
                     PlaceholderSrc={PlaceholderImage}
                     effect="blur"
                  />
               </a>
            </div>
            <a href={props.link} className=" text-decoration-none ">
               <h5
                  className="animate__animated animate__zoomInUp animate__slow"
                  style={{ minHeight: " 2.5rem", maxHeight: " 4rem" }}
               >
                  {props.title}
               </h5>
            </a>
            <h6 className=" text-black-50">{props.gatag}</h6>
         </div>
      </div>
   );
}
