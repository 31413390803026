import React from "react";
import HeroPage from "../components/heropage";
import Work from "../components/work";
import Projects from "../components/projects";
import Certificates from "../components/certificates";
import Contact from "../components/contact";
import { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import About from "../components/about";

export default function Home() {
   const location = useLocation();

   React.useEffect(() => {
      if (location.pathname === "/contact") {
         const section = document.querySelector("#contact-me");
         if (section) {
            const navbarHeight = document.querySelector("#nav-me").offsetHeight;
            const scrollPosition =
               section.getBoundingClientRect().top +
               window.pageYOffset -
               navbarHeight;
            window.scrollTo({ top: scrollPosition, behavior: "smooth" });
         }
      }
   }, [location]);

   React.useEffect(() => {
      if (location.pathname === "/about") {
         const section = document.querySelector("#about-me");
         if (section) {
            const navbarHeight = document.querySelector("#nav-me").offsetHeight;
            const scrollPosition =
               section.getBoundingClientRect().top +
               window.pageYOffset -
               navbarHeight;
            window.scrollTo({ top: scrollPosition, behavior: "smooth" });
         }
      }
   }, [location]);

   React.useEffect(() => {
      if (location.pathname === "/works") {
         const section = document.querySelector("#works");
         if (section) {
            const navbarHeight = document.querySelector("#nav-me").offsetHeight;
            const scrollPosition =
               section.getBoundingClientRect().top +
               window.pageYOffset -
               navbarHeight;
            window.scrollTo({ top: scrollPosition, behavior: "smooth" });
         }
      }
   }, [location]);

   React.useEffect(() => {
      if (location.pathname === "/certificates") {
         const section = document.querySelector("#certificates");
         if (section) {
            const navbarHeight = document.querySelector("#nav-me").offsetHeight;
            const scrollPosition =
               section.getBoundingClientRect().top +
               window.pageYOffset -
               navbarHeight;
            window.scrollTo({ top: scrollPosition, behavior: "smooth" });
         }
      }
   }, [location]);

   React.useEffect(() => {
      if (location.pathname === "/") {
         window.scrollTo({ top: 0, behavior: "smooth" });
      }
   }, [location]);

   return (
      <>
         <HeroPage />
         <Work />
         <Projects />
         <Certificates />
         <About />
         <Contact />
         <Toaster />
      </>
   );
}
