import about from "../pics/about.png";
import aboutback from "../pics/about-back.png";
import React from "react";
import bootstrap from "../pics/bootstrap.png";
import c from "../pics/c.png";
import java from "../pics/java.png";
import sql from "../pics/sql.png";
import react from "../pics/reactjs.png";
import html from "../pics/html.png";
import css from "../pics/css.png";
import material from "../pics/material.png";
import mongodb from "../pics/mongodb.png";
import node from "../pics/node.png";
import python from "../pics/python.png";
import git from "../pics/control.png";
import figma from "../pics/figma.png";
import LazyLoad from "react-lazyload";
export default function About() {
   return (
      <div className="about-me container" id="about-me">
         <div className="mt-3 mb-4  ">
            <h3
               className="spani mb-1 text-center mb-2"
               style={{ textShadow: " 2px 2px 2px lightskyblue" }}
            >
               About me
            </h3>
            <h6 className=" text-black-50 text-center ">
               Web & App Developer | UI/UX Designer
            </h6>
         </div>

         <div className="row m-0">
            <div className="col-12 col-md-4 firstpart p-3 pb-0 p-md-1 ">
               <div
                  className="about-img "
                  style={{ backgroundImage: `url(${aboutback})` }}
               >
                  <img src={about} alt="about" className="img-fluid" />
               </div>
            </div>
            <div
               className="col-12 col-md-8 secondpart"
               style={{ marginTop: "100px" }}
            >
               <LazyLoad height={200} once>
                  <div className="d-flex mb-2 ">
                     <h1 className="me-1 ">Hey! I am</h1>
                     <h1
                        className="spani mb-1 text-center mb-2 animate__animated animate__heartBeat "
                        id="mouad_kh"
                     >
                        Mouad Kahla{" "}
                     </h1>{" "}
                  </div>
               </LazyLoad>
               <h5 style={{ opacity: "60%", fontSize: "1rem" }}>
                  Dedicated and proficient Web and App Developer with a strong
                  background in UI/UX design, committed to crafting visually
                  stunning and highly functional digital products. Skilled in
                  implementing a diverse range of technologies, including a deep
                  understanding of UX principles and extensive experience
                  working with React and Bootstrap frameworks.
                  <br></br>My goal is to bring your project to life with a
                  creative and innovative approach that meets your needs and
                  delights your users.
               </h5>
            </div>
         </div>
         <LazyLoad height={700}>
            <div className="m-lg-5 mt-lg-0 mb-lg-0 pt-lg-0 pb-lg-0 p-lg-5">
               <div className="row row-cols-3 row-cols-md-5 p-2 m-2 m-md-5 p-md-5 justify-content-center skills-board">
                  <div className="col d-flex align-items-center justify-content-center ">
                     <div className="d-flex h-75 w-75 align-items-center justify-content-center">
                        <img
                           src={html}
                           alt="html"
                           title="HTML"
                           className="img-fluid "
                        />
                     </div>
                  </div>
                  <div className="col d-flex align-items-center justify-content-center mb-2">
                     <div className="d-flex h-75 w-75 align-items-center justify-content-center">
                        <img
                           src={css}
                           alt="css"
                           title="CSS"
                           className="img-fluid"
                        />
                     </div>
                  </div>
                  <div className="col d-flex align-items-center justify-content-center mb-2">
                     <div className="d-flex h-75 w-75 align-items-center justify-content-center">
                        <img
                           src={java}
                           alt="javaScript"
                           title="JavaScript"
                           className="img-fluid"
                        />
                     </div>
                  </div>
                  <div className="col d-flex align-items-center justify-content-center mb-2">
                     <div className="d-flex h-75 w-75 align-items-center  justify-content-center">
                        <img
                           src={bootstrap}
                           alt="bootstrap"
                           title="Bootstrap"
                           className="img-fluid p-2"
                        />
                     </div>
                  </div>
                  <div className="col d-flex align-items-center justify-content-center mb-2">
                     <div className="d-flex h-75 w-75 align-items-center justify-content-center">
                        <img
                           src={react}
                           alt="react"
                           title="React.js"
                           className="img-fluid"
                        />
                     </div>
                  </div>
                  <div className="col d-flex align-items-center justify-content-center mb-2">
                     <div className="d-flex h-75 w-75 align-items-center justify-content-center">
                        <img
                           src={material}
                           alt="material"
                           title="Material-UI"
                           className="img-fluid"
                        />
                     </div>
                  </div>
                  <div className="col d-flex align-items-center justify-content-center mb-2">
                     <div className="d-flex h-75 w-75 align-items-center justify-content-center">
                        <img
                           src={node}
                           alt="node"
                           title="Node.js"
                           className="img-fluid"
                        />
                     </div>
                  </div>

                  <div className="col d-flex align-items-center justify-content-center mb-2">
                     <div className="d-flex h-75 w-75 align-items-center justify-content-center">
                        <img
                           src={mongodb}
                           alt="mongodb"
                           title="MongoDB"
                           className="img-fluid"
                        />
                     </div>
                  </div>
                  <div className="col d-flex align-items-center justify-content-center mb-2">
                     <div className="d-flex h-75 w-75 align-items-center justify-content-center">
                        <img
                           src={sql}
                           alt="sql"
                           title="SQL"
                           className="img-fluid"
                        />
                     </div>
                  </div>

                  <div className="col d-flex align-items-center justify-content-center mb-2">
                     <div className="d-flex h-75 w-75 align-items-center justify-content-center">
                        <img
                           src={python}
                           alt="python"
                           title="Python"
                           className="img-fluid p-2"
                        />
                     </div>
                  </div>
                  <div className="col d-flex align-items-center justify-content-center mb-2">
                     <div className="d-flex h-75 w-75 align-items-center justify-content-center">
                        <img
                           src={c}
                           alt="c"
                           title="C"
                           className="img-fluid p-2"
                        />
                     </div>
                  </div>
                  <div className="col d-flex align-items-center justify-content-center mb-2">
                     <div className="d-flex h-75 w-75 align-items-center justify-content-center">
                        <img
                           src={git}
                           alt="git"
                           title="git"
                           className="img-fluid"
                        />
                     </div>
                  </div>
                  <div className="col d-flex align-items-center justify-content-center mb-2">
                     <div className="d-flex h-75 w-75 align-items-center justify-content-center">
                        <img
                           src={figma}
                           alt="figma"
                           title="figma"
                           className="img-fluid"
                        />
                     </div>
                  </div>
               </div>
            </div>
         </LazyLoad>
      </div>
   );
}
