import React from "react";
import contactimg from "../pics/contact.png";
import toast from "react-hot-toast";
import fivrrL from "../pics/fivrrL.png";
import fivrrD from "../pics/fivrrD.png";

export default function Contact() {
   const bottomRef = React.useRef(null);
   const notifysucc = () =>
      toast.success("Your Email has been sent successfully!");
   const notifyerr = () =>
      toast.error("Something went wrong. Please try again later.");
   const notifyfull = () => toast.error("Please fill all the fields!");
   const [hovered, setHovered] = React.useState(false);
   const [fullName, setFullName] = React.useState("");
   const [email, setEmail] = React.useState("");
   const [phone, setPhone] = React.useState("");
   const [service, setService] = React.useState("");
   const [message, setMessage] = React.useState("");
   const [success, setSuccess] = React.useState(false);

   const handleSubmit = (e) => {
      e.preventDefault();

      // Validate the form fields
      if (!fullName || !email || !service || !message) {
         notifyfull();
         return;
      }

      // Validate the email format using a regular expression
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
         alert("Please enter a valid email address.");
         return;
      }

      // Send email
      fetch("https://portfolio-api-seven.vercel.app/send-email", {
         method: "POST",

         headers: { "Content-Type": "application/json" },
         body: JSON.stringify({ fullName, email, phone, service, message }),
      })
         .then((response) => {
            if (response.ok) {
               setSuccess(true);
            } else {
               notifyerr();
            }
         })
         .catch(() => {
            notifyerr();
         });
   };

   React.useEffect(() => {
      if (success && bottomRef.current) {
         bottomRef.current.scrollIntoView({ behavior: "smooth" });
      }
   }, [success]);

   if (success) {
      notifysucc();
      return (
         <>
            <div className="mt-3 mb-4 Contact-me">
               <h3 className="spani mb-1 text-center mb-2">
                  Thank you for contacting us!
               </h3>
               <h6 className="text-center">
                  We have received your message and will get back to you as soon
                  as possible.
               </h6>
            </div>
         </>
      );
   } else {
      return (
         <div className="Contact-me" id="contact-me">
            {" "}
            <div className="mt-3 mb-4  ">
               <h3
                  className="spani mb-1 text-center mb-2"
                  style={{ textShadow: " 2px 2px 2px lightskyblue" }}
               >
                  Contact Me
               </h3>
               <h6 className=" text-black-50 text-center ">
                  Anything you want? Just feel free to contact me.
               </h6>
            </div>
            <div className="row m-0 contacti">
               <div
                  className="col-md-5 col-12 mb-4 p-2 p-md-4 firstpart"
                  style={{ backgroundImage: `url(${contactimg})` }}
               >
                  <div
                     className=""
                     style={{
                        marginBottom:
                           window.innerWidth > 767 ? "120px" : "40px",
                     }}
                  >
                     <h3>Contact information</h3>
                     <h6 className="mb-md-5  fw-normal">
                        Fill up the form and I will get back to you within 24
                        hours.
                     </h6>
                  </div>
                  <div className="mt-5 infos d-flex flex-column justify-content-around">
                     <div className="d-flex align-items-center mb-3 mb-md-5">
                        <i className="fas fa-phone fa-lg fa-fw me-3"></i>

                        <h6 className="mb-0">+213675057718</h6>
                     </div>

                     <div className="d-flex align-items-center mb-3 mb-md-5 ">
                        <i className="fas fa-envelope fa-lg me-4"></i>
                        <a
                           href="mailto:mouadev3@gmail.com"
                           className=" text-decoration-none "
                           style={{ color: "#212529" }}
                        >
                           <h6 className="mb-0">mouadev3@gmail.com</h6>
                        </a>
                     </div>

                     <div className="d-flex align-items-center mb-3 mb-md-5">
                        <i className="fab fa-linkedin fa-lg me-4"></i>{" "}
                        <a
                           href="https://www.linkedin.com/in/mouad-kahla"
                           className=" text-decoration-none "
                           style={{ color: "#212529" }}
                        >
                           <h6 className="mb-0">Mouad Kahla</h6>
                        </a>
                     </div>
                  </div>

                  <div className="">
                     <ul className="wrapper">
                        <li className="icon linkedin">
                           <span className="tooltip">Linkedin</span>
                           <span>
                              {" "}
                              <a
                                 href="https://www.linkedin.com/in/mouad-kahla"
                                 className=""
                                 style={{ color: "inherit" }}
                              >
                                 <i className="fa-brands fa-linkedin fs-3 "></i>
                              </a>
                           </span>
                        </li>

                        <li className="icon github">
                           <span className="tooltip">Github</span>
                           <span>
                              <a
                                 href="https://github.com/23Mouad"
                                 style={{ color: "inherit" }}
                              >
                                 <i className="fa-brands fa-github fs-3"></i>
                              </a>
                           </span>
                        </li>

                        <li className="icon facebook">
                           <span className="tooltip">Facebook</span>
                           <span>
                              {" "}
                              <a
                                 href="https://www.facebook.com/profile.php?id=100090883335804"
                                 style={{ color: "inherit" }}
                              >
                                 <i className="fa-brands fa-facebook fs-3"></i>{" "}
                              </a>
                           </span>
                        </li>
                        <li
                           className="icon fiverr"
                           onMouseEnter={() => setHovered(true)}
                           onMouseLeave={() => setHovered(false)}
                        >
                           <span className="tooltip">Fiverr</span>
                           <span className="d-flex align-items-center justify-content-center">
                              <a
                                 href="https://www.fiverr.com/mouad_3"
                                 style={{
                                    color: "inherit",
                                    textAlign: "center",
                                 }}
                              >
                                 <img
                                    src={hovered ? fivrrD : fivrrL}
                                    alt="fiverr"
                                    className="img-fluid  w-50 "
                                 />
                              </a>
                           </span>
                        </li>
                     </ul>
                  </div>
               </div>

               <div className="col-md-7 col-12  mb-4 p-2 p-md-4 justify-content-center secondpart">
                  <form onSubmit={handleSubmit} className="m-0 p-3 p-md-2">
                     <div className="mb-3">
                        <label htmlFor="fullName" className="form-label">
                           Full Name
                        </label>
                        <input
                           type="text"
                           className="form-control"
                           id="fullName"
                           placeholder="Enter your full name"
                           value={fullName}
                           onChange={(e) => setFullName(e.target.value)}
                        />
                     </div>

                     <div className="row m-0 mb-3">
                        <div className="col-sm-6 p-0 pe-md-2">
                           <label htmlFor="email" className="form-label ">
                              Email
                           </label>
                           <input
                              type="email"
                              className="form-control m-0"
                              id="email"
                              placeholder="Enter your email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                           />
                        </div>
                        <div className="col-sm-6 p-0 ps-md-2">
                           <label htmlFor="phone" className="form-label">
                              Phone
                           </label>
                           <input
                              type="tel"
                              className="form-control"
                              id="phone"
                              placeholder="Enter your phone number"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                           />
                        </div>
                     </div>

                     <div className="mb-3">
                        <label className="form-label">Services</label>
                        <div className="form-check">
                           <input
                              className="form-check-input"
                              type="radio"
                              name="services"
                              id="hire-me"
                              value="hire-me"
                              checked={service === "hire-me"}
                              onChange={(e) => setService(e.target.value)}
                           />
                           <label
                              className="form-check-label"
                              htmlFor="hire-me"
                           >
                              Hire Me
                           </label>
                        </div>
                        <div className="form-check">
                           <input
                              className="form-check-input"
                              type="radio"
                              name="services"
                              id="make-your-website"
                              value="make-your-website"
                              checked={service === "make-your-website"}
                              onChange={(e) => setService(e.target.value)}
                           />
                           <label
                              className="form-check-label"
                              htmlFor="make-your-website"
                           >
                              Make Your Website
                           </label>
                        </div>
                        <div className="form-check">
                           <input
                              className="form-check-input"
                              type="radio"
                              name="services"
                              id="Collaboration"
                              value="Collaboration"
                              checked={service === "Collaboration"}
                              onChange={(e) => setService(e.target.value)}
                           />
                           <label
                              className="form-check-label"
                              htmlFor="Collaboration"
                           >
                              Collaboration
                           </label>
                        </div>
                     </div>

                     <div className="mb-3">
                        <label htmlFor="message" className="form-label">
                           Message
                        </label>
                        <textarea
                           className="form-control"
                           id="message"
                           rows="5"
                           placeholder="Enter your message here"
                           value={message}
                           onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                     </div>

                     <button type="submit" className="submit-btn">
                        <span>Submit</span>
                     </button>
                  </form>
               </div>
            </div>
            <div className="mt-3 mb-4  ">
               <h3
                  className="spani mb-1 text-center mb-2"
                  style={{ textShadow: " 2px 2px 2px lightskyblue" }}
               >
                  Thank you
               </h3>
               <h6 className=" text-black-50 text-center ">
                  I appreciate your interest and hope my work has inspired you
                  in some way
               </h6>
            </div>
            <div className="d-flex justify-content-between p-5 pb-0 pt-3 m-0">
               <h6 className=" text-black-50  ">Mouad Kahla</h6>
               <h6 className=" text-black-50 ">2023</h6>
            </div>
         </div>
      );
   }
}
