import React, { useEffect, useState } from "react";
import Countup from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

export default function Work() {
   const [monthsOfExperience, setMonthsOfExperience] = useState(0);
   const [completedProjects, setCompletedProjects] = useState(0);
   const [completedDesigns, setCompletedDesigns] = useState(0);
   const [show, setShow] = useState(false);

   const startday = new Date("2022-06-09");
   const today = new Date();
   const diff = Math.abs(startday - today);
   const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));

   useEffect(() => {
      setMonthsOfExperience(months);
      setCompletedProjects(3);
      setCompletedDesigns(5);
   }, [months]);

   

   return (
      <>
         <div className="d-flex align-content-center works" id="works">
            <hr style={{ flex: 1 }} />
            <h5 style={{ margin: 0 }}>
               My <span className="spani me-2">Work</span>
            </h5>
            <hr style={{ flex: 3 }} />

            {/* Statics */}

            <ScrollTrigger onEnter={() => setShow(true)} onExit={() => setShow(false)} >
            <div className="row statics m-0">
               <div className="col justify-content-center align-items-center p-0">
                  <h6 className=" text-center" >
                     +{show && <Countup start={0} end={monthsOfExperience} duration={3} />}
                  </h6>
                  <p className=" text-center">Months of Experience</p>
               </div>
               <div className="col justify-content-center align-items-center p-0">
                  <h6 className=" text-center">
                     +{show && <Countup start={0} end={completedProjects} duration={3} />}
                  </h6>
                  <p className=" text-center">Completed project</p>
               </div>
               <div className="col justify-content-center align-items-center p-0">
                  <h6 className=" text-center" >
                  +{show && <Countup start={0} end={completedDesigns} duration={3} />}
                  </h6>
                  <p className=" text-center">Completed UI/UX design</p>
               </div>
               <div className="col justify-content-center align-items-center p-0">
                  <h6 className=" text-center">+{show && <Countup start={0} end={monthsOfExperience} duration={3} />}</h6>
                  <p className=" text-center">Certificates</p>
               </div>
            </div>
            </ScrollTrigger>

            {/* Work Cards */}
            
         </div>
      </>
   );
};

