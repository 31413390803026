import "./App.css";
import { Route, Routes } from "react-router-dom";
import React from "react";
import Home from "./other/Home";
import Loader from "./components/loader";
import Navbar from "./components/navbar";
import Upload from "./other/upload";

import "./verse.css";
function App() {
   const [loading, setLoading] = React.useState(true);
   React.useEffect(() => {
      const timer = setTimeout(() => {
         setLoading(false);
      }, 2000); // Replace this with your own loading logic
      return () => clearTimeout(timer);
   }, []);

   if (loading) {
      return (
         <>
            <Loader />
         </>
      );
   }

   return (
      <>
         <main className={`app `}>
            <Navbar />
            <Routes>
               <Route path="/" element={<Home />} />
               <Route path="/upload" element={<Upload />} />
               <Route path="/works" element={<Home />} />
               <Route path="/certificates" element={<Home />} />
               <Route path="/contact" element={<Home />} />
               <Route path="/about" element={<Home />} />
               <Route path="*" element={<h1>404</h1>} />
            </Routes>
         </main>
      </>
   );
}

export default App;
