import React from "react";
import Card from "../components/card";
import LazyLoad from "react-lazyload";

export default function Certificates() {
   const [Certificates, setCertificates] = React.useState([]);
   const [visibleCertificates, setVisibleCertificates] = React.useState([]);
   const [showMore, setShowMore] = React.useState(
      window.innerWidth >= 767 ? 3 : 2
   );

   React.useEffect(() => {
      fetch("https://portfolio-api-seven.vercel.app/certificates")
         .then((res) => res.json())
         .then((data) => {
            setCertificates(data.certificates);
            setVisibleCertificates(data.certificates.slice(0, showMore));
         });
   }, [showMore]);

   const showMoreCertificates = () => {
      setShowMore((prev) => prev + (window.innerWidth >= 767 ? 6 : 4));
   };
   const handleShowLess = () => {
      setShowMore(window.innerWidth >= 767 ? 3 : 2);
   };

   return (
      <>
         {" "}
         <div
            className=" mb-3 mt-3 d-flex align-content-center linee "
            id="certificates"
         >
            <hr style={{ flex: 1 }} />
            <h5 style={{ margin: 0 }}>
               My <span className="spani me-2 ">Certificate</span>
            </h5>
            <hr style={{ flex: window.innerWidth > 767 ? 6 : 8 }} />
         </div>
         <div className="row row-cols-md-3 m-0 row-cols-2 splide__slide">
            {visibleCertificates.map((item) => (
               <div key={item._id}>
                  <div className="justify-content-center">
                     <LazyLoad height={200}>
                        <Card
                           id={item._id}
                           title={item.title}
                           gatag={item.gatag}
                           link={item.link}
                           image={item.image}
                        />
                     </LazyLoad>
                  </div>
               </div>
            ))}
         </div>
         {Certificates.length > showMore ? (
            <div className="col-12 mt-4 justify-content-center d-flex">
               <button className="seemore mb-3" onClick={showMoreCertificates}>
                  SEE MORE ({Certificates.length - showMore} more)
               </button>x
            </div>
         ) : Certificates.length > (window.innerWidth >= 767 ? 3 : 2) ? (
            <div className="col-12 mt-4 justify-content-center d-flex">
               <button className="seemore mb-3" onClick={handleShowLess}>
                  SHOW LESS
               </button>
            </div>
         ) : null}
      </>
   );
}
